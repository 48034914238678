import React from "react"
import styled from "styled-components"

import AccountWrapper from "../../components/account/account-wrapper"
import BillingAddress from "../../components/account/address/billing-address"

const BillingDetails = ({ location }) => {
  return (
    <AccountWrapper location={location} accountStep="billing-details">
      <StyledH2>Billing Details</StyledH2>
      <BillingAddress key="info-panel" />
    </AccountWrapper>
  )
}

export default BillingDetails

// ===============
//     STYLES
// ===============
const StyledH2 = styled.h2`
  padding-bottom: 20px;
`
